import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Display from './Display';
import Turk from './Turk';
import reportWebVitals from './reportWebVitals';
import heal from './DesertTempleTileset.png';
import sign from './FarmSignbig.png';
import twitterlogo from './Twitter.png';
import youtubelogo from './Youtube.png';
import discordlogo from './Discord.png';
import facebooklogo from './Facebook.png';
class RDZ extends React.Component {

  constructor(props) {
    super(props);
      this.state = {	
		levelID:2	  
		};	
    this.bodyref = React.createRef(); 
	
  }
   

componentDidMount() {
    document.title = "Turk-A-Lurk";
}

  render() {
        const { showing } = this.state;
        const { showing2 } = this.state;
    const min = 0;
    const max = 23;
    const rand = Math.round(min + Math.random() * (max - min));
	const tips = [
	"ONLY MALE TURKEYS GOBBLE",
	"WILD TURKEYS SLEEP IN TREES",
	"BENJAMIN FRANKLIN PREFERRED THE TURKEY TO THE BALD EAGLE",
	"TURKEYS CAN SEE BETTER THAN HUMANS",
	"TURKEYS ARE THE OFFICIAL GAME BIRD OF MASSACHUSETTS ",
	"THE DANGLING PIECE ON A TURKEYS FORHEAD IS CALLED THE SNOOD",
	"THE PIECE HANGING ON A TURKEYS CHIN IS CALLED THE WATTLE",
	"TURKEYS CAN SWIM WHEN FORCED TO",
	"TEDDY ROOSEVELT SAID HUNTING TURKEY IS \"A TRIUMPH FOR THE BEST SPORTSMAN.\"",
	"SWANSONS FIRST FROZEN DINNER WAS LEFTOVER THANKSGIVING DINNERS",
	"JUNE IS NATIONAL TURKEY MONTH",
	"A GROUP OF TURKEYS IS CALLED A RAFTER",
	"TURKEYS WERE SACRAFICED BY THE MAYA",
	 "SWANSONS FIRST FROZEN DINNER WAS LEFTOVER THANKSGIVING DINNERS",
	"JUNE IS NATIONAL TURKEY MONTH",
	"A GROUP OF TURKEYS IS CALLED A RAFTER",
	"TURKEYS WERE SACRAFICED BY THE MAYA",
	"BIG BIRD'S COSTUME IS MADE OF TURKEY FEATHERS",
	"TURKEYS HAVE TWO STOMACHS CALLED THE PROVENTRICULUS AND THE GIZZARD",
	"EATING TURKEY DOESNT ACTUALLY MAKE YOU TIRED",
	"TURKEYS HAVE THE ABILITY TO RECOGNIZE EACHOTHER BY SOUND",
	"TURKEYS CAN VISUALIZE A MAP OF THEIR TERRITORY",
	"TURKEYS CAN RECOGNIZE PATTERNS",
	"BABY TURKEYS DO NOT NEED TO BE NUTURED BY THEIR PARENTS AND CAN FEND FOR THEMSELVES",
	"THE SCIENTIFIC NAME FOR THE TURKEY SPECIES IS MELEAGRIS GALLOPAVO"]
	
  var rows = [], i = 0, len = 20;
  while (++i <= len) rows.push(i);
    return (

      <div id="parent">
	  <div class="App-body" ref={this.bodyref}>
        <h1 className={showing ? 'hidden' : ''}>{tips[rand]}</h1>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/bsih9RB3BlY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		
		<br/>
            <div>
                <a className="App-link2 hidden" onClick={() => this.setState({ showing: !showing })}>{!showing ? 'HIGH SCORES' : '< HIGH SCORES'}</a>
                { showing 
                    ? '' : null
                }
            </div>  
			<a href="https://store.steampowered.com/app/1821000/TurkALurk/?curator_clanid=4777282"><h4>
			VIEW THE STEAM STORE PAGE
            </h4></a>
			

    <tbody className="container">
      {rows.map(function (i) {
        return <div className={!showing ? 'hidden' : ''}><Display dataFromParent={i} /></div>;
      })}
    </tbody>
            
			
	</div>
      </div>
    );
	
  }
}
ReactDOM.render(
  <React.StrictMode>
<div class="fullsize">
	  <div class="App-header" >
		</div>
      <div class="content">
		<App />
		<RDZ />
	
		</div>
	<div class="App-floor"> 
	</div>
	<div class="Sign"> 
        <img src={sign} height="175px" alt="logo" />
	</div>

		<Turk />
	</div>
	
	<div class="wrapper">
	  <div class="">
		<div class="float"></div>
		<a href="abulabs.com">ABULABS INC SINCE 2021</a>
		<br/>
		<div class="floatRight">
			<a href="https://www.youtube.com/channel/UCdP-WfaoTtnO018mD8DgTMg" alt="youtube"><img src={youtubelogo}/></a>
			<br/>
			<a href="http://twitter.com/ABULABSINC"><img src={twitterlogo} alt="twitter"/></a>
			<br/>
			<a href="https://discord.gg/aBDnedNWFH"><img src={discordlogo} alt="discord"/></a>
			<br/>
			<a href="https://www.facebook.com/profile.php?id=100074868978920" alt="facebook"><img src={facebooklogo}/></a>
			<br/>
		</div>
	  </div>
	</div>
	
	<div class="floatUpper">
	  <div class="">
		<div class="float"></div>
		<a class="upperLink" href="http://abulabs.com/turkPrivacyPolicy.html" target="_blank">TURK-A-LURK PRIVACY POLICY</a>
	  </div>
	</div>
	
	
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
