import logo from './GameNameLarge.png';
import './App.css';
function App() {

  return (
    <div className="App">
      <header className="contentz">
        <img class="shrink" src={logo} height="100px" alt="logo" />
      </header>
    </div>
  );
}


export default App;
