import turk from './TurkWalkz.gif';
import duck from './RunnerDuck.gif';
import './Turk.css';
function Turk() {
  return (
    <div className="App">
      <div className="box">
        <img src={turk} height="75px" alt="logo" />
      </div>
      <div className="box2">
        <img src={duck} height="100px" alt="logo" />
      </div>
    </div>
  );
}


export default Turk;
