import React from "react";
import Table from './Table';
import axios from 'axios';

class Display extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
		datos:[{}],
		levelID:props.dataFromParent
	};	  

  }
  
  componentDidMount() {
	  
axios.get('https://abulabs.com/getScores.php', {
  headers: {
    'Level': `${this.props.dataFromParent}`
}})
  .then(response => {
    console.log(response.data);

	   this.setState({
		  datos:response.data 
		}) 
  });
  }
  
  
  render() {
    
  return (
     <div className={this.state.datos != null && typeof(this.state.datos) != "undefined" && this.state.datos[0] != null ? '' : 'hidden'}>
       <pre>
           {this.state.datos != null && typeof(this.state.datos) != "undefined" && this.state.datos[0] != null ? <Table data={this.state.datos}/> : '' } 
       </pre>
     </div>
    );
  }
  
  

}

export default Display;
